import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { Link as RouterLink, NavLink } from "react-router-dom";
import { useRef, useState } from "react";
import { EPUBLICROUTE } from "@constants/enum/public-route.enum";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import { MenuPopover } from "@src/components/menu-popover/menu-popover";
import PersonIcon from "@mui/icons-material/Person";
import womanAvatar from "@assets/images/avatars/avatar_24.jpg";
import { EPRIVATEROUTE } from "@src/constants/enum/private-route.enum";
import { setUser } from "@src/components/layout/private-layout/user-slice";
import { useOidc, useOidcUser } from "@axa-fr/react-oidc";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "@src/redux/hooks";
import { IReduxState } from "@src/redux/root-reducer";
interface IMenuOption {
  label: string;
  icon: JSX.Element;
  linkTo: string;
}
const MENU_OPTIONS: Array<IMenuOption> = [
  {
    label: "Home",
    icon: <HomeIcon />,
    linkTo: EPUBLICROUTE.HOME,
  },
  {
    label: "Profile",
    icon: <PersonIcon />,
    linkTo: EPRIVATEROUTE.PROFILE,
  },
];

// ----------------------------------------------------------------------

export function AccountPopover() {
  const { t } = useTranslation();
  const { logout } = useOidc();
  const dispatch = useAppDispatch();
  const anchorRef = useRef(null);
  const { oidcUser } = useOidcUser();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const profile = useAppSelector((state: IReduxState) => state.user.profile);
  if (oidcUser && profile.email !== oidcUser.email) {
    setTimeout(() => {
      dispatch(setUser({ ...oidcUser }));
    }, 0);
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <div data-testid="account-popover-wrapper">
      <IconButton ref={anchorRef} onClick={handleClick}>
        <Avatar src={womanAvatar} alt="photoURL" />
      </IconButton>

      <MenuPopover open={open} anchorEl={anchorEl} handleClose={handleClose}>
        <Box className="firstBox">
          <Typography variant="subtitle2" noWrap>
            {oidcUser?.name || "_"}
          </Typography>
          <Typography variant="body2" noWrap>
            {oidcUser?.email || "_"}
          </Typography>
        </Box>

        <Divider className="divider" />

        <Stack className="stack">
          {MENU_OPTIONS.map((option: IMenuOption, index: number) => (
            <MenuItem
              key={`user_menu-icon${option.label}_${index}`}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
            >
              <ListItemIcon>{option.icon}</ListItemIcon>
              <ListItemText>{option.label}</ListItemText>
            </MenuItem>
          ))}
        </Stack>

        <Divider className="divider" />

        <MenuItem to={""} component={NavLink} onClick={() => logout()}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>{t("Logout")}</ListItemText>
        </MenuItem>
      </MenuPopover>
    </div>
  );
}
