import React, { useEffect } from "react";
import { Svg, SVG } from "@svgdotjs/svg.js";
import "@svgdotjs/svg.panzoom.js";
import "@svgdotjs/svg.draggable.js";
import { ServiceDiagram } from "@components/service_diagram";

export const Overview: React.FC<{}> = () => {
 /*  useEffect(() => {
    // component mounted
    // eslint-disable-next-line
    const draw = SVG("#svg1") as Svg;
    if (draw.children().length > 0) {
      return;
    }

    draw.viewbox("0 0 1000 1000").panZoom({ zoomMin: 0.5, zoomMax: 20 });
    const rec1 = draw.rect(100, 100);
    rec1.id("rec1");
    rec1.fill("blue").move(100, 100);
    rec1.draggable(true);
    draw.rect(100, 100).fill("green").id("rec2");

    // eslint-disable-next-line
    console.log(draw)
  });
  // eslint-disable-next-line
  debugger;
 */
debugger;
  return (
    <div>
  {/*     <svg
        id={"svg1"}
        width={1000}
        height={1000}
        style={{ backgroundColor: "red" }}
      ></svg> */}
      <hr />
      <h1>service diagram</h1>
      <div style={{ width: "400px", height: "400px" }}>
        <ServiceDiagram></ServiceDiagram>
      </div>
    </div>
  );
};
