import React, { useRef, useEffect } from "react";
import { Svg, SVG } from "@svgdotjs/svg.js";
import "@svgdotjs/svg.panzoom.js";
import "@svgdotjs/svg.draggable.js";


export const ServiceDiagram: React.FC<{}> = () => {
  const svgRef = useRef(null);

  // Access the DOM element after the component mounts
  useEffect(() => {
    if (svgRef.current) {
      // eslint-disable-next-line
      console.log(svgRef.current); // This will log the DOM element
      const draw = SVG(svgRef.current) as Svg;
      
      if (draw.children().length > 0) {
        // items are already rendered.
        return;
      }
      draw
        .size(1000, 1000)
        .viewbox("0 0 1000 1000")
        .panZoom({ zoomMin: 0.1, zoomMax: 20 });
      draw.rect(100, 100).fill("green").draggable();
    }
  }, []);
  return <svg ref={svgRef} width={"1000"} height={"1000"}></svg>;
};
