import { TokenRenewMode } from "@axa-fr/react-oidc";
export const configurationIdentityServer = {
  client_id: `${process.env.REACT_APP_CLIENT_ID}`,
  // redirect_uri: window.location.origin + +"/authentication/callback",
  redirect_uri:
    process.env.REACT_APP_REDIRECT_BASE_URL + "authentication/callback",
  silent_redirect_uri:
    process.env.REACT_APP_REDIRECT_BASE_URL + "authentication/silent-callback",
  scope: "openid email roles",
  authority: "https://idp.void-star.co/realms/detailist.void-star.co",
  refresh_time_before_tokens_expiration_in_second: 40,
  service_worker_relative_url: "/OidcServiceWorker.js",
  service_worker_only: false,
  token_renew_mode: TokenRenewMode.access_token_invalid,
};
